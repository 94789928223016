.player_play_btn {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
  transition: 0.5s;
  &.loading {
    background-size: 94% 94%;
  }
  &.pause {
    background-image: url('../../images/playIcon.svg');
  }

  &.play {
    background-image: url('../../images/pauseIcon.svg');
    &.loading {
      background-image: url('../../images/player/loaing-80.gif');
    }
  }
}
