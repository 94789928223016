.search-title {
  padding-bottom: 20px;
  border-bottom: solid #dcdcdc 1px;
  .t {
    font-size: 26px;
    font-weight: bold;
    color: #000000;
    font-style: italic;
  }
  .des {
    margin-top: 14px;
    line-height: 1.3;
    font-size: 14px;
    color: #757575;
  }
}
