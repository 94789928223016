.box-ellipsis {
  overflow: hidden;
  display: inline-block;
  word-break: break-word;
  width: 100%;

  &-lines {
    position: relative;
    &-shadow {
      display: block;
      position: relative;
      color: transparent;
      opacity: 0;
      z-index: -999;
    }
  }

  &-lineClamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    position: relative;
  }
}
