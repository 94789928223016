.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.search-suggest {
  text-align: left;
  margin-bottom: 30px;
}
.search-suggest-item {
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 12px;
  padding: 4px 14px;
  font-size: 14px;
  color: #757575;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.search-suggest-item:hover {
  color: #F55B23;
  border-color: #F55B23;
}
