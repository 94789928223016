.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.rightFooter {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  height: 44px;
  width: 100%;
  padding: 10px 20px;
}
.rightFooter .footerLinks {
  height: 24px;
  line-height: 24px;
  list-style: none;
  padding: 0;
  float: left;
}
.rightFooter .footerLinks-item {
  font-size: 14px;
  color: #777;
  font-weight: 400;
  margin-right: 20px;
  display: inline-block;
}
.rightFooter .footerLinks-item a {
  color: inherit;
}
.rightFooter .footerLinks-item a:active,
.rightFooter .footerLinks-item a:hover {
  text-decoration: underline;
  color: #000;
}
.rightFooter .footerCounrtySel {
  float: right;
}
.rightFooter .footerCounrtySel .countryCover {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: top;
}
.rightFooter .footerCounrtySel .countryName {
  text-align: right;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rightContainer {
  border-left: 1px solid #dcdcdc;
  margin-left: -1px;
  position: relative;
}
.rightContainer .rightContainer-item {
  width: 300px;
  overflow-x: hidden;
  font-size: 14px;
  margin-left: 20px;
  padding: 20px 0;
  border-top: 1px solid rgba(220, 220, 220, 0.3);
}
.rightContainer .rightContainer-item:first-child {
  border: 0;
}
.rightContainer .adBanner {
  width: 300px;
  padding: 12px 0;
  display: none;
}
.rightContainer .downloadPlay-title {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
}
.rightContainer .downloadPlay-title-empty {
  margin-top: -24px;
}
.rightContainer .downloadPlay-title strong {
  font-weight: 700;
  font-style: italic;
}
.rightContainer .downloadPlay-item {
  margin-top: 24px;
}
.rightContainer .downloadPlay-item .link,
.rightContainer .downloadPlay-item .text {
  float: left;
  width: 50%;
  padding: 0 12px;
}
.rightContainer .rightFooter {
  height: auto;
}
.rightContainer .footer-wrapper.footer-sticky {
  position: fixed;
  top: 350px;
}
