.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.episodeRow {
  position: relative;
  z-index: auto;
}
.episodeRow .ep-item {
  margin: 0 20px 30px 0;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}
.episodeRow .ep-item-cover {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
.episodeRow .ep-item-cover .image {
  width: 100%;
}
.episodeRow .ep-item-cover:hover {
  overflow: visible;
}
.episodeRow .ep-item-con {
  padding-left: 20px;
  padding-right: 80px;
  flex: 1;
  overflow: hidden;
}
.episodeRow .ep-item-con-title {
  font-size: 16px;
  color: black;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.episodeRow .ep-item-con-title .explicit {
  flex-shrink: 0;
  margin-left: 6px;
  width: 16px;
  height: 16px;
}
.episodeRow .ep-item-con-des {
  margin-top: 10px;
  line-height: 18px;
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.episodeRow .ep-item-con-des .item {
  margin-right: 12px;
}
.episodeRow .ep-item-con-des .item .heart {
  height: 11px;
  margin-right: 4px;
}
.episodeRow .ep-item-con-des .item.icon {
  padding-left: 16px;
  display: inline-block;
  background-position: left 2px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}
.episodeRow .ep-item-con-des .item.date {
  background-image: url(../../images/calendar.svg);
}
.episodeRow .ep-item-con-des .item.time {
  background-image: url('../../images/clock.svg');
}
.episodeRow .ep-item-con-btns {
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.episodeRow .ep-item-con-btns .btn-item {
  width: 23px;
  height: 20px;
  cursor: pointer;
  margin-right: 20px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: inline-block;
}
.episodeRow .ep-item-con-btns .btn-item.del {
  width: 22px;
  height: 20px;
  background-image: url(../../images/user/delete.svg);
}
.episodeRow .ep-item-con-btns .btn-item.like {
  background-image: url(../../images/like.svg);
}
.episodeRow .ep-item-con-btns .btn-item.liked {
  background-image: url(../../images/liked.png);
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl {
  width: 24px;
  height: 18px;
  position: relative;
  z-index: 100;
  display: inline-block;
  margin-right: 15px;
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl.more-point {
  width: 25px;
  height: 24px;
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl .add-2epl-btn {
  width: 24px;
  height: 18px;
  display: inline-block;
  background-size: 24px 18px;
  position: relative;
  background: url(../../images/user/add2eplbtn.svg) no-repeat left center;
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl .add-2epl-btn.epl-more-action {
  width: 25px;
  height: 24px;
  background: url(../../images/user/moreAction.svg) no-repeat left center;
  background-size: cover;
  opacity: 0.8;
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl .playlists-modal {
  height: 100%;
}
.episodeRow .ep-item-con-btns .btn-item.add-2epl .playlists-modal-list {
  top: 18px;
}
.episodeRow .ep-item-ctrls {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.episodeRow .ep-item-ctrls .ctrlItem {
  width: 30px;
  height: 30px;
  float: right;
  cursor: pointer;
  margin-right: 20px;
  background-size: auto 100%;
}
.episodeRow .ep-item-ctrls .ctrlItem.play {
  background-image: url(../../images/playiconbtn_pc.svg);
}
.episodeRow .ep-item-ctrls .ctrlItem.play:hover,
.episodeRow .ep-item-ctrls .ctrlItem.play:focus {
  background-image: url(../../images/play-hover.svg);
}
.episodeRow .ep-item-desmodal {
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
  z-index: -1;
  font-size: 14px;
  line-height: 19px;
  height: 116px;
  top: 0;
  left: 115px;
  right: 100px;
  border-radius: 8px;
  padding: 20px 12px;
}
.episodeRow .ep-item-desmodal-con {
  height: 100%;
  overflow-y: auto;
  word-break: break-word;
}
.episodeRow .ep-item-desmodal-con::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: inherit;
}
.episodeRow .ep-item-desmodal-con::-webkit-scrollbar {
  width: 3px;
  margin-left: 20px;
  background-color: inherit;
}
.episodeRow .ep-item-desmodal-con::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(158, 158, 158, 0.6);
}
.episodeRow .ep-item-desmodal-con::-webkit-scrollbar-thumb:hover {
  background-color: rgba(158, 158, 158, 0.9);
}
.episodeRow .ep-item-desmodal::before {
  content: ' ';
  position: absolute;
  left: -10px;
  top: 15px;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 14px solid rgba(0, 0, 0, 0.87);
}
.episodeRow.onImgFocus .ep-item-desmodal {
  z-index: 111;
  opacity: 1;
}
.episodeRow.audiosearch .ep-item {
  margin-bottom: 0;
}
.episodeRow.audiosearch .audiobox {
  margin: 10px 0 30px;
  padding-right: 20px;
}
.episodeRow.audiosearch .audiobox .audiosearch-item {
  font-size: 12px;
  height: 16px;
  width: 100%;
  margin: 6px 0;
  line-height: 16px;
  overflow: hidden;
}
.episodeRow.audiosearch .audiobox .audiosearch-item-play,
.episodeRow.audiosearch .audiobox .audiosearch-item-time {
  display: inline-block;
  vertical-align: top;
}
.episodeRow.audiosearch .audiobox .audiosearch-item-play {
  height: 16px;
  width: 16px;
  background-image: url(../../images/playiconbtn_pc.svg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.episodeRow.audiosearch .audiobox .audiosearch-item-play:hover,
.episodeRow.audiosearch .audiobox .audiosearch-item-play:active,
.episodeRow.audiosearch .audiobox .audiosearch-item-play:focus {
  background-image: url(../../images/play-hover.svg);
}
.episodeRow.audiosearch .audiobox .audiosearch-item-time {
  margin-right: 10px;
}
.episodeRow.audiosearch .audiobox .audiosearch-item-text em {
  color: red;
}
.ep-item.isSmall {
  position: relative;
  margin: 0 0 20px 0;
  height: 80px;
  width: 300px;
}
.ep-item.isSmall .ep-item-cover {
  width: 80px;
  height: 80px;
}
.ep-item.isSmall .ep-item-con-btns {
  margin-top: 8px;
  height: 22px;
}
.ep-item.isSmall .ep-item-con {
  padding-left: 20px;
  padding-right: 0;
  max-width: 220px;
}
.ep-item.isSmall .ep-item-con-title {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ep-item.isSmall .ep-item-con-des {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}
.ep-item.isSmall .ep-item-con-des .item {
  margin-right: 12px;
}
.ep-item.isSmall .ep-item-con-des .item .heart {
  height: 16px;
  margin-right: 4px;
}
.ep-item.isSmall .ep-item-ctrls {
  position: absolute;
  right: -1000px;
  transition: 0.3s;
  top: 6px;
  transform: translateY(0);
  padding: 0;
  z-index: 0;
}
.ep-item.isSmall .ep-item-ctrls:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  z-index: -1;
}
.ep-item.isSmall .ep-item-ctrls .ctrlItem {
  width: 30px;
  height: 30px;
  float: left;
  cursor: pointer;
  margin: 4px 10px;
  background-size: auto 100%;
}
.ep-item.isSmall .ep-item-ctrls .ctrlItem.play {
  background-image: url(../../images/playiconbtn_pc.svg);
}
.ep-item.isSmall .ep-item-ctrls .ctrlItem.play:hover,
.ep-item.isSmall .ep-item-ctrls .ctrlItem.play:focus {
  background-image: url(../../images/play-hover.svg);
}
.ep-item.isSmall .ep-item-ctrls .ctrlItem.like {
  background-image: url(../../images/like.svg);
}
.ep-item.isSmall .ep-item-ctrls .ctrlItem.liked {
  background-image: url(../../images/liked.svg);
}
.ep-item.isSmall.onFocus .ep-item-ctrls {
  right: 0;
}
.finish.ep-item {
  opacity: 0.4;
}
.finish.ep-item .ep-item-con-title {
  color: #777;
}
.userCenterMain .historyList .genreList.isdrag {
  padding: 0;
}
.userCenterMain .historyList .genreList.isdrag .episodeRow .ep-item {
  margin: 0;
  height: 130px;
  position: relative;
}
.userCenterMain .historyList .genreList.isdrag .episodeRow .ep-item::before {
  content: '';
  margin-right: 8px;
  width: 16px;
  height: 32px;
  background: url(../../images/dargicon.svg) no-repeat center center;
  background-size: 100% 40%;
}
