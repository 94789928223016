.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.coverRow {
  float: left;
  display: block;
  width: 160px;
  height: 224px;
  overflow: hidden;
  margin: 0 15px 20px;
}
.coverRow .topCover {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 4px;
}
.coverRow .count {
  background-color: #f59716;
  border-radius: 50%;
}
.coverRow .bottomCon {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0;
  height: auto;
  overflow: hidden;
}
.coverRow .bottomCon .title,
.coverRow .bottomCon .author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.coverRow .bottomCon .title {
  font-size: 14px;
  color: black;
  font-weight: 400;
  margin: 0;
  height: 19px;
  line-height: 19px;
}
.coverRow .bottomCon .des {
  margin-top: 10px;
  font-size: 12px;
  color: #757575;
  letter-spacing: 0;
  line-height: 18px;
}
.coverRow .bottomCon .author {
  color: #9f9f9f;
  font-weight: 400;
  /* color: #A8A8A8; */
  margin-top: 7px;
  font-size: 12px;
  height: 17px;
  line-height: 17px;
}
.coverRow.rowLeft {
  width: 475px;
  float: left;
  margin: 0 0 20px;
  padding: 0 30px;
  height: 100px;
  border: none;
}
.coverRow.rowLeft .topCover {
  float: left;
  margin: 0;
  width: 100px;
  height: 100px;
}
.coverRow.rowLeft .topCover .img {
  width: 100%;
  height: 100%;
}
.coverRow.rowLeft .row-sub {
  float: right;
  margin-right: 0;
  width: 30px;
  height: 100px;
  position: relative;
}
.coverRow.rowLeft .row-sub-img {
  cursor: pointer;
  background-image: url(../../images/subBtn.png);
  background-size: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
}
.coverRow.rowLeft .row-sub .is-subed .row-sub-img {
  background-image: url(../../images/subedBtn.png);
}
.coverRow.rowLeft .bottomCon {
  padding: 0 20px 0;
  float: left;
  width: 285px;
  padding: 0 0 0 20px;
  height: 100px;
  margin: 0;
}
.coverRow.rowLeft .bottomCon .title {
  margin-top: 28px;
  font-size: 14px;
  color: black;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.coverRow.rowLeft .bottomCon .author {
  margin-top: 6px;
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
  width: 100%;
  height: 18px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
