.playlists-modal {
  @h: 68px;
  height: 68px;
  width: 23px;
  display: inline-block;
  &-target {
    display: inline-block;
  }
  &-btn {
    font-size: 12px;
    width: 17px;
    height: 13px;
    padding: 0 7px;
    display: inline;
    vertical-align: middle;
  }
  &-list {
    background: #fff;
    width: 200px;
    padding: 15px 0 0;
    border: 1px solid #ebebeb;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    @left_pad: 23px;
    .list-item {
      padding: 8px @left_pad;
      cursor: pointer;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.54);
      &.hover:hover {
        background: #f55b23;
        color: #fff;
      }
    }
    .new-playlist {
      padding: 8px 0 0;
      border-top: 1px solid #dcdcdc;
      &-btn {
        padding: 8px @left_pad;
        margin: 0 -@left_pad;
        &:hover {
          background: #f55b23;
          color: #fff;
        }
      }
    }
  }
}
.userCerterTopNavBar .topNavBar-con {
  .topNavBar-item .active + .playlists-modal {
    #epl-select-icon {
      fill: #f55b23;
    }
  }
  .playlists-modal-btn {
    &:hover {
      #epl-select-icon {
        fill: #f55b23;
      }
    }
  }
  #epl-select-icon {
    fill: #777;
  }
}
.rc-tooltip.playlists-modal-overlay {
  padding: 0;
  opacity: 1;
  z-index: 100;
  .rc-tooltip-arrow {
    display: none;
  }
  .rc-tooltip-inner {
    padding: 0;
    background: inherit;
    box-shadow: none;
  }
  .playlists-modal-list {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  }
  &.playlists-sel-tooltip .playlists-modal-list {
    margin: -16px 0 0 -68px;
  }
}
.new-playlist {
  &-modal {
    position: absolute;
    // z-index: 100;
    top: 50%;
    left: 50%;
    margin: -126px 0 0 -180px;
    width: 360px;
    height: 252px;
    .modal-content,
    .modal-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .modal-content {
      overflow: hidden;
    }
  }
  @title_h: 50px;
  @input_h: 36px;
  @btn_h: 36px;
  @pad: 20px;
  &-title {
    padding: 0 @pad;
    height: @title_h;
    line-height: @title_h;
    background: #f2f2f2;
    color: #000;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
  }
  &-con {
    padding: 0 @pad;
    .ctrls {
      margin-top: 70px;
      text-align: right;
      .c-btn {
        height: @btn_h;
        line-height: @btn_h;
        font-size: 14px;
        text-align: center;
        padding: 0 14px;
        border-radius: 4px;
      }
      .ok {
        background: #f55b23;
        color: #fff;
        border: #f55b23 solid 1px;
        &:hover,
        &[disabled] {
          color: #f55b23;
          background: #fff;
        }
        &[disabled] {
          cursor: progress;
        }
      }
      .cancel {
        border: 1px solid #dcdcdc;
        background: #f2f2f2;
        margin-right: 20px;
        color: rgba(0, 0, 0, 0.54);
        &:hover {
          background: #fff;
        }
      }
    }
  }
  &-input {
    margin: 30px 0 0;
    font-size: 14px;
    width: 100%;
    padding: 0 14px;
    border-radius: 4px;
    height: @input_h;
    line-height: @input_h;
    color: rgba(0, 0, 0, 0.32);
    text-align: left;
    border: 1px solid #dcdcdc;
    &:active,
    &:hover,
    &:focus {
      border: 1px solid #f55b23;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  &-msg {
    position: relative;
    height: 0;
    width: 100%;
  }
  &-err {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: rgba(255, 0, 0, 0.8);
    font-size: 0.1rem;
    line-height: 14px;
    margin-top: 8px;
  }
}
