.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.subscribeBtn {
  cursor: pointer;
  min-width: 120px;
  height: 40px;
  border: 2px solid #F55B23;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  color: #F55B23;
  background-color: rgba(0, 0, 0, 0);
  padding: 0 10px 0 36px;
  background: url(../../images/channelSubBtn.svg) no-repeat;
  background-size: 20px 26px;
  background-position: 8px 5px;
}
.subscribeBtn:hover,
.subscribeBtn:focus,
.subscribeBtn .focus {
  color: #F55B23;
}
.is-subed .subscribeBtn {
  background-image: url(../../images/channelSubedBtn.svg);
  color: #68C006;
  border-color: #68C006;
}
.is-subed .subscribeBtn:hover,
.is-subed .subscribeBtn:focus,
.is-subed .subscribeBtn .focus {
  color: #68C006;
}
