.countryListSelModal{
  max-width: 500px;
  .modal-title{
    font-size: 18px;
  }
  .countryItem{
    border-bottom: #ccc solid 1px;
    @h: 48px;
    margin: 0 @h/2;
    padding: @h/2 0;
    .icon, .countryName{
      float: left;
    }
    .icon{
      width: @h;
      margin-right: 20px;
    }
    .countryName{
      color: #000;
      line-height: @h;
    }
  }
}
.footer_select_country{
  cursor: pointer;
  .countryName{
    color: #757575;
    font-size: 14px;
    &:hover{
      color: #000;
      text-decoration: underline;
    }
  }
}
